import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import {
  WrapPbSectionStyled,
  WrapPbSectionInnerStyled,
} from './WrapPbSectionStyled';

export default function WrapPbSection({ children, transparentBg, goldenBackground }) {
  return (
    <StaticQuery
    query={graphql`
      query {
        goldTexture: file(relativePath: { eq: "effects/gold.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024, toFormat: PNG) {
              src
            }
          }
        }
      }
    `}
    render={data => (
      <WrapPbSectionStyled transparentBg={transparentBg} goldenBackground={goldenBackground} goldTexture={data?.goldTexture?.childImageSharp?.fluid.src}>
        <WrapPbSectionInnerStyled>{children}</WrapPbSectionInnerStyled>
      </WrapPbSectionStyled>
    )}
  />
  );
}
