import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const VideoWrapperStyled = styled('div')({
  width: '100%',
  padding: '25px 0 25px 0',

  [media('tablet')]: {
    padding: '25px 8% 25px 8%',
  },

  [media('tabletFluid')]: {
    width: '90%',
    padding: '50px 0',
    marginRight: '10%',
  },
});

export const VideoWrapperInnerStyled = styled('div')({
  padding: '0 24px',

  [media('tabletFluid')]: {
    padding: '0',
  },
});
