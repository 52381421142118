import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import Image from 'components/common/ImageGatsbyCraft';

import {
  ImageWrapperStyled,
  ImageWrapperInnerStyled,
} from './NewsBuilderImageStyled';

export default function NewsBuilderImage({ data }) {
  const { image } = data;

  return (
    <WrapPbSection transparentBg>
      <ImageWrapperStyled>
        <ImageWrapperInnerStyled>
          <Image
            critical
            fadeIn={false}
            fluid={image?.[0]}
            args={{ maxWidth: 800 }}
          />
        </ImageWrapperInnerStyled>
      </ImageWrapperStyled>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment NewsBuilderImageQuery on Craft_NewsBuilderNeoImage {
    id
    image {
      ...CraftImageFluid
    }
  }
`;
