import { useState } from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';

export const useScrolledIntoView = ({
  retrigger = false,
  target,
  threshold,
  rootMargin,
}) => {
  const [scrolledIntoView, setScrolledIntoView] = useState(false);

  function handleIntersect([{ isIntersecting }]) {
    if (retrigger) {
      setScrolledIntoView(isIntersecting);
    } else if (!scrolledIntoView && isIntersecting) {
      setScrolledIntoView(true);
    }
  }

  function reset() {
    setScrolledIntoView(false);
  }

  useIntersectionObserver({
    target,
    onIntersect: handleIntersect,
    threshold,
    rootMargin,
  });

  return [scrolledIntoView, reset];
};
