import styled from 'utils/emotion';

export const WrapPbSectionStyled = styled('div')(
  {
    position: 'relative',
    width: '100%',
  },
  (props) => ({
    backgroundColor: props.theme.colorSecondary,
  }),
  (props) =>
    props.transparentBg && {
      backgroundColor: 'transparent',
    },
  (props) =>
    props.goldenBackground && {
      background: `url(${props.goldTexture})`,
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
    },
);

export const WrapPbSectionInnerStyled = styled('div')({
  maxWidth: '100%',
  margin: '0 auto',
});
