import React from 'react';
import { graphql } from 'gatsby';

import NewsBuilderRichText from 'components/container/NewsBuilderRichText';
import NewsBuilderImage from 'components/container/NewsBuilderImage';
import NewsBuilderVideo from 'components/container/NewsBuilderVideo';

import {
  ContainerStyled,
  ButtonWrapperStyled,
  ButtonStyled,
} from './NewsBuilderStyled';

/**
 * Function:
 * Get component.
 */
const availableComponents = {
  RichText: NewsBuilderRichText,
  Image: NewsBuilderImage,
  Video: NewsBuilderVideo,
};

function getComponent(__typename) {
  const componentName = __typename.replace('Craft_NewsBuilderNeo', '');
  return availableComponents[componentName] || false;
}

/**
 * Component:
 * News builder.
 *
 * Renders predefined components dynamically.
 */
export default function NewsBuilder({ components, backgroundImage }) {
  return (
    <ContainerStyled>
      {components.map((el, index) => {
        const PageBuilderComponent = getComponent(el.__typename);
        const key = `${el.id}-${index}`;

        return PageBuilderComponent ? (
          <PageBuilderComponent
            data={el}
            key={key}
            backgroundImage={backgroundImage}
          />
        ) : null;
      })}
      <ButtonWrapperStyled>
        <ButtonStyled to="/">Zurück zur Startseite</ButtonStyled>
      </ButtonWrapperStyled>
    </ContainerStyled>
  );
}

/**
 * Fragment:
 * Spreading fragments for page builder components.
 */
export const query = graphql`
  fragment NewsBuilderQuery on Craft_NewsBuilderNeoUnion {
    __typename
    ...NewsBuilderRichTextQuery
    ...NewsBuilderImageQuery
    ...NewsBuilderVideoQuery
  }
`;
