import media from 'utils/mediaqueries';
import styled from 'utils/emotion';

import Text from 'components/common/Text';
import Button from 'components/common/Button';

const RichTextStyles = (props) => ({
  opacity: 1,

  ul: {
    listStyle: 'disc outside none',
    paddingLeft: '20px',
  },
  ol: {
    paddingLeft: '20px',
  },
  'p a': {
    // Select only links in paragraphs to avoid ugly inheritance issues.
    // Directly selecting the links is ugly enough as is. Please avoid/fix. ~RS
    position: 'relative',
    display: 'inline-block',

    '&:after': {
      backgroundColor: props.theme.colorPrimary,
      content: '""',
      position: 'absolute',
      display: 'block',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '1px',
      transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
    },

    '&:hover': {
      '&:after': {
        bottom: '3px',
      },
    },
  },
  li: {
    margin: `${props.theme.spaceHalf}px 0`,
  },
  h1: {
    margin: `0 0 ${props.theme.space}px 0`,
    [media(738, 'desktop')]: {
      margin: `0 0 ${props.theme.spaceQuartered}px 0`,
    },
    [media('desktop')]: {
      margin: `0 0 ${props.theme.spaceDouble}px 0`,
    },
  },
  h2: {
    margin: `0 0 ${props.theme.space}px 0`,
    [media('desktop')]: {
      margin: `0 0 ${props.theme.spaceQuartered}px 0`,
    },
  },
  h3: {
    margin: `0 0 ${props.theme.space}px 0`,
  },
  h4: {
    margin: `0 0 ${props.theme.spaceThirds}px 0`,
  },
  h5: {
    margin: `0 0 ${props.theme.spaceThirds}px 0`,
  },
  h6: {
    margin: `0 0 ${props.theme.spaceHalf}px 0`,
  },

  'p + h1, ul + h1, ol + h1': {
    margin: `${props.theme.spaceQuartered}px 0 0 0`,
  },
  'p + h2, ul + h2, ol + h2': {
    margin: `${props.theme.spaceQuartered}px 0 0 0`,
  },
  'p + h3, ul + h3, ol + h3': {
    margin: `${props.theme.space}px 0 0 0`,
  },
  'p + h4, ul + h4, ol + h4': {
    margin: `${props.theme.space}px 0 0 0`,
  },
  'p + h5, ul + h5, ol + h5': {
    margin: `${props.theme.space}px 0 0 0`,
  },

  'p + h6, ul + h6, ol + h6': {
    margin: `${props.theme.space}px 0 0 0`,
  },

});

export const RichTextStyled = styled(Text)({}, (props) => ({
  ...RichTextStyles(props),
},
(props) =>
    props.goldenBackground && {
      h2: {
        color: '#000000',
        '-webkit-text-fill-color': 'initial'
      },
      p: {
        color: '#000000',

        a: {
          color: '#000000',

          '&:hover': {
            color: '#000000',
            borderColor: '#000000',

            '&:before': {
              color: '#000000',
            },
            '&:after': {
              color: '#000000',
            }
          }
        }
      },
      a: {
        color: '#000000',

        '&:hover': {
          color: '#000000',
          borderColor: '#000000',

          '&:before': {
            color: '#000000',
          },
          '&:after': {
            color: '#000000',
          }
        }
      }
    }
  )
);

export const RichTextMoreStyled = styled(Text)((props) => ({
  '> span': {
    display: 'block',
    height: props.displayToggle ? 'auto' : 0,
    opacity: props.displayToggle ? 1 : 0,
    transition: '0.4s opacity linear',
    '> *': {
      display: props.displayToggle ? 'block' : 'none',
    },
  },
  ...RichTextStyles(props),
}));

export const RichTextMoreButtonStyled = styled(Button)((props) => ({
  marginTop: props.theme.space,
  marginBottom: props.theme.spaceHalf,

  [media('tabletFluid')]: {
    marginTop: props.theme.spaceDouble,
    marginBottom: props.theme.space,
  },
}));
