import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

import Button from 'components/common/Button';

export const ContainerStyled = styled('div')({
  padding: '40px 0 80px',

  [media('tabletFluid')]: {
    paddingTop: 0,
  },
});

export const ButtonWrapperStyled = styled('div')({
  textAlign: 'center',
});

export const ButtonStyled = styled(Button)(
  {
    marginBottom: 0,
  },
  (props) => ({
    marginTop: props.theme.spaceDouble,
  }),
);
