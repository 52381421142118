import React from 'react';
import { graphql } from 'gatsby';
import { parseAsContent } from 'utils/parser';

import WrapPbSection from 'components/common/WrapPbSection';

import {
  VideoWrapperStyled,
  VideoWrapperInnerStyled,
} from './NewsBuilderVideoStyled';

export default function NewsBuilderVideo({ data }) {
  const { videoEmbed } = data;

  return (
    <WrapPbSection transparentBg>
      <VideoWrapperStyled>
        <VideoWrapperInnerStyled>
          {parseAsContent(videoEmbed?.content)}
        </VideoWrapperInnerStyled>
      </VideoWrapperStyled>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment NewsBuilderVideoQuery on Craft_NewsBuilderNeoVideo {
    id
    videoEmbed {
      content
    }
  }
`;
