import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import 'moment/locale/de';

import Seo from 'components/container/Seo';
import NewsBuilder from 'components/container/NewsBuilder';
import Stage from 'components/common/Stage';

export default function TemplateNews({ data, pageContext }) {
  const url = pageContext?.url;
  const {
    title,
    seoTitle,
    seoDescription,
    seoImage,
    newsBuilderNeo,
    newsIntroImage,
    newsIntroHeadline,
    newsIntroText,
    newsBlogDate,
  } = data?.craft?.entry;

  const newsBlogDateFormatted = moment
    .unix(newsBlogDate)
    .locale('de')
    .format('D. MMMM YYYY');

  const introTagline = newsBlogDateFormatted;

  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription}
        url={url}
        image={seoImage?.[0]?.socialMediaUrl}
      />
      <Stage
        image={newsIntroImage}
        headline={newsIntroHeadline?.content}
        tagline={introTagline}
        text={newsIntroText?.content}
        newsStage
      >
        <NewsBuilder components={newsBuilderNeo} />
      </Stage>
    </>
  );
}

export const query = graphql`
  query QueryNews($id: [Int]!) {
    craft {
      entry(id: $id) {
        id
        title
        slug
        ... on Craft_News {
          seoTitle
          seoImage {
            socialMediaUrl: url(transform: transformSocialMedia)
          }
          seoDescription
          newsBuilderNeo {
            ...NewsBuilderQuery
          }
          newsBlogDate
          newsIntroImage {
            id
            url
            ...CraftImageFluid
          }
          newsIntroText {
            content
          }
          newsIntroHeadline {
            content
          }
          newsCat {
            id
          }
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "bg-1.jpg" }) {
      ...imageDefault
    }
  }
`;
